@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
@import url("./Components/QuestionBuilder/QuestionPreview/questionpreview.scss");

:root {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 150%;
}

:root {
  --toastify-color-success: #def7ec;
  --toastify-color-warning: #fdf6b2;
  --toastify-color-error: #fde8e8;

  --toastify-text-color-success: #046c4e;
  --toastify-text-color-warning: #8e4b10;
  --toastify-text-color-error: #c81e1e;
}

.Toastify__close-button {
  color: #000;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background-color: #046c4e;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background-color: #8e4b10;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: #c81e1e;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main {
  height: 100vh;
  width: 100vw;
}

.iframe-container {
  height: 600px;
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   // background: #fff;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.grid-table-container {
  &::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.625rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #929292;
  }
}

a {
  text-decoration: none;
}

.sidebar.collapsed {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar {
  height: 100vh;
  width: 240px;
  // transition: width 0.2s;
  transition: width 0.2s ease-in;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  border-right: 1px solid #e5e7eb;
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sider-menu {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    border-radius: 8px;
    cursor: pointer;
    // .side-bar-icon {
    //   path {
    //     fill: #ffffff;
    //   }
    // }
  }

  // .side-bar-icon {
  //   .side-bar-icon-rect {
  //     fill: rgba(255, 255, 255, 0.1);
  //   }
  //   path {
  //     fill: #327091;
  //   }
  // }
  // }
}
.user-management-menu {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  border-radius: 12px;
  cursor: pointer;
  // .side-bar-icon {
  //   path {
  //     fill: #ffffff;
  //   }
  // }
  font-weight: 100;
}
.active.user-management-menu {
  .MuiTypography-root {
    font-weight: 600;
  }

  // .side-bar-icon {
  //   .side-bar-icon-rect {
  //     fill: rgba(255, 255, 255, 0.1);
  //   }
  //   path {
  //     fill: #327091;
  //   }
  // }
}

.rearrange-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 12px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  .shuffle-handler {
    margin-right: 15px;
  }
}

.rearrange-ingredient-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  display: flex;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #e0e3eb;
  border-radius: 6px;
}

.wmde-markdown {
  font-family: "Poppins", sans-serif;
}
.w-md-editor {
  .w-md-editor-toolbar {
    height: 50px;
    padding: 8px;
    ul {
      li {
        button {
          width: 24px;
        }
      }
    }
  }
}

.study-print-container {
  .print-button {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    @media print {
      display: none;
    }
  }
  .print-form-container {
    margin-bottom: 20px;
    padding: 20px;
    .print-crf-table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      .crf-table-row,
      .crf-table-header {
        & > td,
        & > th {
          border-bottom: 1px solid #e5e7eb;
          padding: 10px;
          text-align: left;
        }
        & > td {
          font-size: 16px;
        }
      }
    }
  }
  .print-list-table {
    border-collapse: collapse;
    td,
    th {
      border: 1px solid #e5e7eb;
      min-width: 150px;
      padding: 10px;
    }
  }
}

.tiptap {
  &.ProseMirror {
    outline: none;
    padding: 8px;
    min-height: 150px;
  }
}

.shadow-left {
  .scrollable-table {
    .fixed-col {
      &.left::after {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        width: 5px;
        pointer-events: none;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }
}
.shadow-right {
  .scrollable-table {
    .fixed-col {
      &.right::before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        width: 5px;
        pointer-events: none;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }
}


.calendar-view {
  width: 100% !important;
  max-height: none !important;
  height: 100% !important;
  .MuiDateCalendar-viewTransitionContainer {
    flex: 1;
    & > div {
      height: 100%;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }
      .MuiDayCalendar-slideTransition {
        flex: 1;
        .MuiDayCalendar-monthContainer {
          display: flex;
          flex-direction: column;
          gap: 14px;
          padding-right: 8px;
          min-height: 100%;
        }
      }
    }
  }
  .MuiPickersCalendarHeader-root {
    padding: 0;
    padding-right: 8px;
    margin-bottom: 40px;
    .MuiPickersCalendarHeader-labelContainer {
      font-size: 24px;
      font-weight: 600;
      cursor: default;
    }
  }
  .MuiDayCalendar-header {
    justify-content: space-between;
    padding-right: 8px;
    .MuiDayCalendar-weekDayLabel {
      font-size: 18px;
      font-weight: 500;
      cursor: default;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(1)::after {
      content: "o";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(2)::after {
      content: "u";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(3)::after {
      content: "e";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(4)::after {
      content: "h";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(5)::after {
      content: "r";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(6)::after {
      content: "a";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(7)::after {
      content: "u";
      display: inline-block;
    }
  }

  .MuiDayCalendar-weekContainer {
    justify-content: space-between;
    .MuiPickersDay-root {
      font-size: 18px;
      font-weight: 600;
      color: #828282;
      border: 1.5px solid #828282;
    }

    .Mui-disabled {
      color: lightgrey;
      border-color: lightgrey;
    }
  }
}
